import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faSpinner as fasSpinner, faUserCircle as fasUserCircle, faGlobe as fasGlobe} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falQuoteLeft, falQuoteRight);
// regular
library.add(farSearch, farCircle);
// solid
library.add(faChevronRight, faChevronLeft, faChevronDown, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, fasUserCircle, fasGlobe);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP);

dom.i2svg();
dom.watch();