import $ from "jquery";
import "parsleyjs";
export class Forms {
    forms = null;
    parsleyParams = {
        excluded: Parsley.options.excluded + ', input:hidden, select:hidden, textarea:hidden',
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback leading-tight"></div>',
        errorTemplate: '<span></span>',
        errorsContainer: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible:last');
            }
        },
        classHandler: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible input');
            }
        }
    }

    constructor($selector = $('form')) {
        if ($selector.length > 0) {
            this.forms = $selector;
        }
    }

    validate() {
        if (this.forms) {
            this.forms.parsley(this.parsleyParams)
        }
        $("#gotcha").change(function(){
            if($(this).val() == "") {
                $("#gotchbtn").show();
            } else {
                $("#gotchbtn").hide();
            }
        });
    }

    steps() {
        var $sections = $('.form-section');
        var next = $('.form-navigation .next');
        var prev = $('.form-navigation .previous');

        $('input[name="fields[jointInitiative]"]').on('change',function(){
            var element = document.getElementById('fields-letterOfCooperation-field');
            var elementNotice = document.getElementById('fields-joint-notice');

            if (this.value === "no") {
                prev.data('index', curIndex());
                next.data('index', 2);
                element.classList.add("d-none");
                elementNotice.classList.remove("d-none");
            }
            else 
            {
                prev.data('index', false);
                next.data('index', false);
                element.classList.remove("d-none");
                elementNotice.classList.add("d-none");
            }
           
        })

    

        function navigateTo(index) {
            // Mark the current section with the class 'current'
            $sections
                .removeClass('d-block')
                .addClass('d-none')
                .eq(index)
                .addClass('d-block');
            // Show only the navigation buttons that make sense for the current section:
            prev.toggle(index > 0);
            var atTheEnd = index >= $sections.length - 1;
            next.toggle(!atTheEnd);
            $('.form-navigation [type=submit]').toggle(atTheEnd);
        }

        function curIndex() {
            // Return the current index by looking at which section has the class 'current'
            return $sections.index($sections.filter('.d-block'));
        }

        // Previous button is easy, just go back
        prev.click(function () {
            let index = $(this).data('index');
            navigateTo(index !== false ? index : curIndex() - 1);
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        });

        // Next button goes forward iff current block validates
        next.click(function () {
            let form = $(this).closest('form');
            let index = $(this).data('index');

            form.parsley(this.parsleyParams).whenValidate({
                group: 'block-' + curIndex()
            }).done(function () {
                navigateTo(index !== false ? index : curIndex() + 1);
            });
                
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        });

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        $sections.each(function (index, section) {
            $(section).find('.input [required]').attr('data-parsley-group', 'block-' + index);
        });
        navigateTo(0); // Start at the beginning
    }

}