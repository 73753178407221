import $ from "jquery";
import Cookies from 'js-cookie';

$(window).on('load', function() {
    var cookieValue = Cookies.get('cookies');
    if (!cookieValue) {
    	$('#cookies-box').modal('show');
    }
    $('#cookies-box .accept-btn').click(function() {
      	Cookies.set('cookies', '1', { expires: 365 }); 
      	$('#cookies-box').modal('hide');
      	// var elem = document.getElementById("_etLoader");
		//     var attrs = elem.attributes;
		//     attrs["data-block-cookies"].value = "false";
    	// 	$('#_etLoader').find(attrs["data-block-cookies"].value);
        // window._etracker.enableTracking('https://drm-innovation.fund/');
    });
    // $('.withdraw-btn').click(function() {
    //     Cookies.set('cookies', '0', { expires: 0 });
    //     $('.consent').addClass('d-none');
    //     $('.withdrawn-consent').removeClass('d-none');
    //     var elem = document.getElementById("_etLoader");
    //     var attrs = elem.attributes;
    //     attrs["data-block-cookies"].value = "true";
    //     $('#_etLoader').find(attrs["data-block-cookies"].value);
    //     window._etracker.disableTracking('https://drm-innovation.fund/')
    // });

    $('#cookies-box .decline-btn').click(function() {
      	$('#cookies-box').modal('hide');
    });
});